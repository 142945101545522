@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Alegreya|Alegreya+Sans&display=swap');

@responsive {
    .rotate-3\/4 {
        transform: rotate(-90deg);
    }
}

.transition {
    transition: all .3s;
}

.bg-gray-transparent {
    background-color: rgba(0,0,0,0.6);
}

.bg-black-transparent {
    background-color: rgba(0,0,0,0.8);
}

.content {
    @apply leading-normal text-gray-400;

    h1,h2,h3 {
        @apply font-semibold tracking-wide text-white;
    }

    h4 { @apply text-lg; }
    h3 { @apply text-xl; }
    h2 { @apply text-2xl; }
    h1 { @apply text-3xl; }

    *:not(:first-child) {
        @apply mt-4;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}